export class ValidDate {
  constructor(
    public validFrom: Date,
    public validUntil?: Date,
  ) {
  }

  public static cutTimeOfDate(date: Date): Date {
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);

    return date;
  }

  public static isEndless(date: Date): boolean {
    const endless = ValidDate.cutTimeOfDate(new Date('2999-01-01'));
    date = ValidDate.cutTimeOfDate(new Date(date));
    return endless.getTime() === date.getTime();
  }
}
