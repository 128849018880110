import { Pipe, PipeTransform } from '@angular/core';
import {CssPropertiesEnum} from "../models/css-properties.enum";
import {VHostService} from "../services/v-host.service";
@Pipe({name: 'cssProperty'})
export class CssPropertyPipe implements PipeTransform {
  transform(value: CssPropertiesEnum, vHostService: VHostService): string {
    let result = '';
    if (vHostService.vHost) {
      vHostService.vHost?.css?.forEach(cssProperty => {
        if (cssProperty.key === value) {
          result = cssProperty.value;
        }
      });
    }
    return result;
  }
}
