import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseDataService} from '../../core/services/base-data.service';
import {ContactService} from '../../data/services/contact.service';
import {BaseData} from '../../data/models/base-data.model';
import {SnackbarService} from '../../core/services/snackbar.service';
import {ContactForm} from '../../data/models/contact-form.model';
import {NavigationCommand, NavigationService} from '../../data/services/navigation.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  errorsArr: string[] = [];
  contactTypes: BaseData[] = [];
  send: boolean = false;
  isSaving: boolean = false;
  @Input() unauthorized: boolean = false;
  @Input() userBackendId: number = 0;
  @Input() addressForm: FormGroup;
  @Input() emailAddress: string;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  contactFormModel: ContactForm = null;

  constructor(
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    private baseDataService: BaseDataService,
    private contactService: ContactService,
    private snackBar: SnackbarService,
    private navigationService: NavigationService,
  ) {
    this.contactTypes = this.baseDataService.getBaseData('contactrequesttypes');
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      reason: ['', Validators.required],
      memo: ['', Validators.required],
    });
    this.send = false;
  }

  onSendContact(): void {
    this.errorsArr = [];
    this.buildContactFormModel();
    this.isSaving = true;
    if (this.contactForm.valid) {
      this.contactService.createContactRequest(this.contactForm.value, this.contactForm, this.errorsArr, this.unauthorized, this.userBackendId, this.contactFormModel)
        .subscribe(
          {
            error: (error) => this.isSaving = false,
            complete: () => {
              this.contactForm.reset();
              this.send = true;
              this.send = true;
              this.saved.emit(true);
              this.navigationService.navigateTo(NavigationCommand.CONTACTOVERVIEW).then((navigated) => {
                if (navigated) {
                  this.snackBar.openSnackbar(this.translationService.getTranslation('saved'));
                }
              });
              this.isSaving = false;
            }
          });
    }
  }

  buildContactFormModel(): void {
    this.contactFormModel = {} as ContactForm;
    if (this.addressForm) {
      const address: AbstractControl = this.addressForm.get('address');
      const person: AbstractControl = this.addressForm.get('person');

      this.contactFormModel.street = address.get('street').value;
      this.contactFormModel.houseno = address.get('houseNo').value;
      this.contactFormModel.zipCode = address.get('zipcode').value;
      this.contactFormModel.city = address.get('city').value;
      this.contactFormModel.country = address.get('country').value?.key;
      this.contactFormModel.additional2 = address.get('extraLine').value;

      this.contactFormModel.firstName = person.get('firstName').value;
      this.contactFormModel.lastName = person.get('lastName').value;
      this.contactFormModel.salutation = person.get('salutation')?.value['key'];
      this.contactFormModel.title = person.get('title').value;

      this.contactFormModel.email = this.emailAddress;
    }
  }

  protected readonly document = document;
}
