export class PaymentTransactionModel {
  amount: number;
  invoiceText: string;
  transactionId: string;
  provider: PaymentProvider;
}

export enum PaymentProvider {
  BUCKAROO,
  PAYPAL,
}
