import {Injectable} from '@angular/core';
import {VHostService} from "./v-host.service";

@Injectable({providedIn: 'root'})
export class TranslationService {
  constructor(
    private vHostService: VHostService
  ) {
  }

  getTranslation(key: string): string {
    const _vHost = this.vHostService.vHost;
    if (typeof _vHost !== 'undefined' && _vHost && _vHost['translation']) {
      if (_vHost['translation'][key]) {
        return _vHost['translation'][key];
      } else {
        return "";
      }
    }
    return '[' + key + ']';
  }
}
