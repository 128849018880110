import {Component, OnInit} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";
import {ConfigService} from "../../../core/services/config.service";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";

@Component({
  selector: 'app-contact-overview',
  templateUrl: './contact-overview.component.html',
  styleUrls: ['./contact-overview.component.css']
})
export class ContactOverviewComponent implements OnInit {

  showBackToOverviewButton: boolean;

  constructor(public translationService: TranslationService,
              private configService: ConfigService,
              private navigationService: NavigationService,) {
  }

  ngOnInit(): void {
    this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
      if( config && config.value) {
        this.showBackToOverviewButton = config.value === '1' || config.value === 'true';
      }
    });
  }

  backToOverview(): void {
    this.navigationService.navigateTo(NavigationCommand.CONTACT, null, true);
  }

}
