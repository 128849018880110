import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EMPTY, Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ErrorService} from "../../core/components/general-errors/error.service";
import {FormGroup} from "@angular/forms";
import {ContactForm} from "../models/contact-form.model";

@Injectable({providedIn: 'root'})
export class ContactService extends ErrorService{

  constructor(private http: HttpClient,) {
    super();
  }

  createContactRequest(body, formGroup: FormGroup, errors, unauthorized: boolean, userBackendId: number, contactFormModel: ContactForm): Observable<any> {
    let url = "";
    const salt = new Date().getTime();
    if (this.isContactFormFilled(contactFormModel)) {
      contactFormModel.contactReason = body.reason;
      contactFormModel.memo = body.memo;
      url = '/webabo/contacts/forms';
      body = contactFormModel;
    } else if (unauthorized) {
      url = '/webabo/contacts/unauthorized';
      body.userBackendId = userBackendId;
    } else {
      url = '/webabo/contacts';
    }
    return this.http.post(url+'?'+salt, body).pipe(map(res => {
      }), catchError(error => {
      console.log(error);
      this.handleAPIError([formGroup], error.error, errors);
      return EMPTY;
    })
    );
  }

  isContactFormFilled(contactForm: ContactForm): boolean {
    let filled: boolean = false;

    if (contactForm.firstName && contactForm.firstName !== '') {
      filled = true;
    }
    if (contactForm.lastName && contactForm.lastName !== '') {
      filled = true;
    }
    if (contactForm.salutation && contactForm.salutation !== '') {
      filled = true;
    }
    if (contactForm.title && contactForm.title !== '') {
      filled = true;
    }
    if (contactForm.street && contactForm.street !== '') {
      filled = true;
    }
    if (contactForm.houseno && contactForm.houseno !== '') {
      filled = true;
    }
    if (contactForm.zipCode && contactForm.zipCode !== '') {
      filled = true;
    }
    if (contactForm.city && contactForm.city !== '') {
      filled = true;
    }
    if (contactForm.email && contactForm.email !== '') {
      filled = true;
    }
    if (contactForm.additional2 && contactForm.additional2 !== '') {
      filled = true;
    }
    return filled;
  }
}
