import {
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";
import {Offer} from "../../../data/models/offer.model";
import {ConfigService} from "../../../core/services/config.service";
import {Bonus} from "../../../data/models/bonus.model";
import {OfferPrice} from "../../../data/models/price.model";
import {BaseData} from "../../../data/models/base-data.model";
import {MatStepper} from "@angular/material/stepper";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {PaymentProviderService} from "../../../core/services/payment-provider.service";
import {PaymentTransactionModel} from "../../../core/components/onlinepayment/paymentTransaction.model";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.css']
})
export class OfferCardComponent implements OnInit, OnChanges {

  @Input() offer: Offer;
  @Input() showOnOfferPage: boolean = true;
  @Input() bonus: Bonus;
  @Input() deliveryAddress: any;
  @Input() billingAddress: any;
  @Input() bonusAddress: any;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() prices: OfferPrice[];
  @Input() paymentCode: BaseData;
  @Input() paymentFrequency: BaseData;
  @Input() stepper: MatStepper;
  @Input() creatingOrder: boolean = false;
  @Input() onlinePayment: boolean = false;
  @Output() save: EventEmitter<PaymentTransactionModel> = new EventEmitter<PaymentTransactionModel>();
  highlightIds: string[];
  @ViewChild('saveButtonPoint')saveButtonPoint: ElementRef<HTMLElement>;
  @ViewChild('offerCard')offerCard: ElementRef<HTMLElement>;
  transaction: PaymentTransactionModel;
  constructor(
    private navigationService: NavigationService,
    public translationService: TranslationService,
    public configService: ConfigService,
    public sanitizer: DomSanitizer,
    private paymentProvider: PaymentProviderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const offerIds = this.configService.getConfig('offer.highlight.offerId');
    if (offerIds?.value !== '') {
      this.highlightIds = offerIds?.value?.split(',');
    }

    this.paymentProvider.getPaymentProviderResultData().subscribe(transaction => {
      if (transaction && transaction?.transactionId && transaction.transactionId !== '') {
        this.transaction = transaction;
        this.saveOrder();
      }
    });
  }

  ngOnInit(): void {
  }

  openOrder(id: number): void{
    this.navigationService.navigateTo(NavigationCommand.OFFER, new Array(id+''));
  }

  saveOrder(): void {
    this.save.emit(this.transaction);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId) && changes.prices && !changes.prices.firstChange) {
      const elementPosition = this.saveButtonPoint?.nativeElement?.getBoundingClientRect()?.top;
      const bodyPosition = document?.body?.getBoundingClientRect()?.top;
      if ( elementPosition && elementPosition > 0) {
        let offset = 550;
        if ( this.stepper.orientation === 'vertical') {
          offset = 350;
        }
        const offsetPosition = elementPosition - offset - bodyPosition;
        window.scrollTo({top: offsetPosition, behavior: "smooth"});
      }
    }
  }

}
