import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {PaymentProviderEnum} from './payment.provider.enum';
import {PaymentStatusEnum} from './payment.status.enum';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss']
})
export class PaymentRedirectComponent implements OnInit {
  paymentProvider: PaymentProviderEnum;
  paymentStatus: PaymentStatusEnum;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(params => {
      if (params.get('brq_payment_method')) {
        this.paymentProvider = PaymentProviderEnum.IDEAL;
        this.handleIdealResult(params);
      }
    });
  }

  private handleIdealResult(params: ParamMap): void {
    const statusCode = params.get('brq_statuscode');
    if (statusCode) {
      switch (statusCode) {
        case '190': {
          this.paymentStatus = PaymentStatusEnum.SUCCESSFUL;
          break;
        }
        case '791': {
          this.paymentStatus = PaymentStatusEnum.PENDING;
          break;
        }
        case '490': {
          this.paymentStatus = PaymentStatusEnum.ERROR;
          break;
        }
        case '690': {
          this.paymentStatus = PaymentStatusEnum.ERROR;
          break;
        }
        case '890': {
          this.paymentStatus = PaymentStatusEnum.CANCELLED;
          break;
        }
      }
    }
  }

  ngOnInit(): void {
    console.log('redirect');
  }

}
