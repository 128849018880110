<ul class="list-unstyled">
  <li *ngIf="person">
    {{person.salutationDescription}} {{person.titleDescription}} {{person.firstname}} {{person.lastname}}
  </li>
  <li *ngIf="address">
    {{address.street}} {{address.houseno | houseNoPipe}}
  </li>
  <li *ngIf="address">
    {{address.zipcode}} {{address.city}}, {{address.countryDescription}}
  </li>
</ul>
