import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AddressSearch} from "../../data/models/address-search.model";

@Injectable({
  providedIn: 'root'
})
export class AddressSearchService {
  constructor(private http: HttpClient) {
  }

  searchAddress(addressSearch: AddressSearch, limit: number = 10): Observable<AddressSearch[]> {
    return this.http.post<AddressSearch[]>('/address/address-search?limit=' + limit, addressSearch).pipe(map(addressSearchResult => {
      return addressSearchResult;
    }));
  }

  searchZipcode(addressSearch: AddressSearch, limit: number = 10): Observable<AddressSearch[]> {
    return this.http.post<AddressSearch[]>('/address/zipcode-search?limit=' + limit, addressSearch).pipe(map(addressSearchResult => {
      return addressSearchResult;
    }));
  }

  searchCity(addressSearch: AddressSearch, limit: number = 10): Observable<AddressSearch[]> {
    return this.http.post<AddressSearch[]>('/address/city-search?limit=' + limit, addressSearch).pipe(map(addressSearchResult => {
      return addressSearchResult;
    }));
  }

  searchStreet(addressSearch: AddressSearch, limit: number = 10): Observable<AddressSearch[]> {
    return this.http.post<AddressSearch[]>('/address/street-search?limit=' + limit, addressSearch).pipe(map(addressSearchResult => {
      return addressSearchResult;
    }));
  }
}
