import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from "@angular/core";
import {Observable, of} from "rxjs";
import {VHostService} from "./v-host.service";
import {CssPropertiesEnum} from "../models/css-properties.enum";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CssService {
  cssEnum = CssPropertiesEnum
  private renderer: Renderer2;
  constructor(
    private vHostService: VHostService,
    @Inject(PLATFORM_ID) private platformId,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setStyles(): Observable<void> {
    const vHost = this.vHostService.vHost;
    vHost.css.forEach(style => {
        if (!(style.key === 'css.customCss')) {
          const key = '--' + Object.keys(this.cssEnum)[Object.values(this.cssEnum).indexOf(style.key)];
          // P5W-2668: REMS | Rahmentemplate fehlerhaft - fehlende Farbwerte führen zu fehlerhaftem HTML
          const value = style.value !== '#' ? style.value : 'initial';
          if (isPlatformBrowser(this.platformId)) {
            this.document.documentElement.style.setProperty(key, value);
          } else {
            this.renderer.setStyle(this.document.documentElement, key, value);
          }
        }
    });
    let resultObservable;

    return resultObservable;
  }

  appendCss(styles: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const style = this.document.createElement('style');
      style.textContent = styles;
      this.document.head.append(style);
    } else {
      const style = this.renderer.createElement('style');
      style.textContent = styles;
      this.renderer.createText(style)
    }
  }
}
