import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Bonus} from '../../../../data/models/bonus.model';
import {TranslationService} from '../../../../core/services/translation.service';

@Component({
  selector: 'app-bonus-detail',
  templateUrl: './bonus-detail.component.html',
  styleUrls: ['./bonus-detail.component.scss']
})
export class BonusDetailComponent implements OnInit {
  bonus: Bonus;
  showBonusPoints: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { bonus: Bonus, showBonusPoints: boolean },
    public translationService: TranslationService
  ) {
    this.bonus = data.bonus;
    this.showBonusPoints = data.showBonusPoints;
  }

  ngOnInit(): void {
    // this.translationService.loadTranslationGroup('order');
  }

}
