import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {RESPONSE} from "@nguniversal/express-engine/tokens";
import {Response} from "express";
import {Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response?: Response) {

  }

  redirect(location: string, history = false) {
      //console.log(this.response);
      if(this.response) {
          //wir sind im ssr
          this.response.status(307);
          this.response.location(location);
          this.response.send(null);
          this.router.dispose();
      } else if(isPlatformBrowser(this.platformId)) {
          if(history) {
              window.location.href = location;
          } else {
              window.location.replace(location);
          }
      }
  }
}
