import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {ConfigService} from './config.service';

@Injectable({providedIn: 'root'})
export class ConfigResolverService implements Resolve<any> {
  constructor(private configService: ConfigService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const configs: [] = route.data.config;
    const observableBatch = [];
    let observe = false;

    configs.forEach((type: string) => {
      const config = this.configService.getConfig(type);
      if (!config) {
        observe = true;
        observableBatch.push(this.configService.loadConfig(type));
      }
    });

    if (observe) {
      return forkJoin(observableBatch);
    }
    else { // Ein leeres forkJoin feuert success() nie -> Pfad wäre dann tot
      return;
    }
  }
}
