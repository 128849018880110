<ng-container [formGroup]="controlGroup">
  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value.key !== 'A' && controlGroup.get('address.country').value !== 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames"
             (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input maxlength="10" matInput formControlName="houseNo" (keyup)="replaceInvalidCharactersFromHouseNo()" required #houseNumber>
      <mat-hint *ngIf="houseNumber === document.activeElement && houseNumber.value.length >= 1" align="end">{{houseNumber.value.length}} / {{houseNumber.maxLength}}</mat-hint>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value === 'A' || controlGroup.get('address.country').value.key === 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames" (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input maxLength="10" matInput formControlName="houseNo" (keyup)="replaceInvalidCharactersFromHouseNo()" required #houseNumber>
      <mat-hint *ngIf="houseNumber === document.activeElement && houseNumber.value.length >= 1" align="end">{{houseNumber.value.length}} / {{houseNumber.maxLength}}</mat-hint>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stiege')"></mat-label>
      <input maxLength="2" matInput formControlName="stiege" #stiege>
      <mat-hint *ngIf="stiege === document.activeElement && stiege.value.length >= 1" align="end">{{stiege.value.length}} / {{stiege.maxLength}}</mat-hint>
      <mat-error
        *ngIf="controlGroup.get('address.stiege').hasError('message')">{{controlGroup.get('address.stiege').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stock')"></mat-label>
      <input maxLength="2" matInput formControlName="stock" #stock>
      <mat-hint *ngIf="stock === document.activeElement && stock.value.length >= 1" align="end">{{stock.value.length}} / {{stock.maxLength}}</mat-hint>
      <mat-error
        *ngIf="controlGroup.get('address.stock').hasError('message')">{{controlGroup.get('stock').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_tuer')"></mat-label>
      <input maxLength="3" matInput formControlName="tuer" #door>
      <mat-hint *ngIf="door === document.activeElement && door.value.length >= 1" align="end">{{door.value.length}} / {{door.maxLength}}</mat-hint>
      <mat-error
        *ngIf="controlGroup.get('address.tuer').hasError('message')">{{controlGroup.get('address.tuer').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>
