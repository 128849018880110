export enum SubChangeTypeEnum {
  INTERMISSION = 0,
  EPAPER = 10,
  DONATION_WELFARE = 21,
  DONATION_POOL = 22,
  FORWARD = 30,
  COLLECTION = 40
}

export class SubChangeType {
  constructor(
    public key: SubChangeTypeEnum,
    public code: string,
    public description: string
  ) {
  }
}
