import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../login/login.service";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  ssoCode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.ssoCode = params.code;
          this.loginService.loginByCode(this.ssoCode).subscribe((result) => {
            this.router.navigate(['/subscription'], {queryParamsHandling: "preserve"});
          });
        }
      );
  }

}
