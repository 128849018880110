import {LandingPage} from "../models/landing-page.model";
import {EMPTY, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {Globals} from "../../core/globals";

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  loadedAll = false;
  landingPages: LandingPage[] = [];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
  }

  getLandingPages(): Observable<LandingPage[]> {
    if (this.loadedAll) {
      return of(this.landingPages);
    }
    const landingPages = JSON.parse(localStorage.getItem('landingPages'));
    if (landingPages && landingPages?.length > 0) {
      return of(landingPages);
    }
    return this.http.get<LandingPage[]>('/webabo/landingpages').pipe(map(landingPages => {
      this.loadedAll = true;
      if (landingPages) {
        landingPages.forEach(page => {
          if (page.viewImg && page.viewfile && page.viewfile.file && page.viewfile.type) {
            page.viewImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + page?.viewfile?.type + ';base64,' + page?.viewfile?.file);
          }
          if (page.file) {
            page.landingImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + page?.file?.type + ';base64,' + page?.file?.file);
          }
          page.isExtern = page.externLink && page.externLink != '';
        })
        this.landingPages = landingPages;
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + Globals.CACHE_TIME_BASE_DATA);
        localStorage.setItem('landingPages', JSON.stringify({expirationDate, landingPages}));
        return this.landingPages;
      }
    }));
  }

  resetData(): void {
    this.loadedAll = false;
    this.landingPages = [];
  }

  getPageOfUrl(url: string): Observable<LandingPage> {
    if (url) {
      return this.getLandingPages().pipe(map(pages => {
        let foundPage = null;
        pages.forEach(page => {
          if (page && !page.isExtern && page.link == url) {
            foundPage = page;
          }
        });
        return foundPage;
      }));
    } else {
      return of(null);
    }
  }
}
