import {Inject, Injectable, Optional, PLATFORM_ID} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {REQUEST} from "@nguniversal/express-engine/tokens";
import {Observable, of} from "rxjs";
import {isPlatformServer} from "@angular/common";
import {catchError, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VHostService {

  private _vHost: any;

  constructor(private httpClient: HttpClient,
              @Inject(PLATFORM_ID) private platformId: Object,
              @Optional() @Inject(REQUEST) protected request?: Request) {
  }

  public loadVhosts(): Observable<void> {

    let resultObservable;

    if (this._vHost) {
      resultObservable = of();
    } else {
      resultObservable = this.fetchvHost()
        .pipe(
          map(unmappedSettings => unmappedSettings),
          tap(_vHost => {
            this._vHost = _vHost;
            return null;
          }),
          catchError(error => {
            console.log(error);
            return of();
          })
        )
    }

    return resultObservable;
  }

  private fetchvHost(): Observable<any> {
    let _vHost;

    if(isPlatformServer(this.platformId) && this.request) {
      _vHost = this.request['vHost'];
    } else {
      //wird vom ssr ins dokument geschrieben,
      //nicht als global deklariert, damit die variable nicht an anderen stellen genutzt wird
      // @ts-ignore
      _vHost = vHost;
    }

    if(_vHost) {
      return of(_vHost);
    } else {
      console.log("WAS TUN?")
      return of(null);
    }
  }

  get vHost(): any {
    return this._vHost;
  }

}
