import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Globals} from '../../core/globals';

@Injectable({providedIn: 'root'})
export class LandingPageGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const useLandingPage = Globals.USE_LANDING_PAGE;
    return of(useLandingPage).pipe(map(() => {
      if (useLandingPage) {
        return true;
      }
      return this.router.createUrlTree(['/']);
    }));
  }
}
