import {SubExtendedDataField} from "./sub-extended-data-field";

export enum InputTypeEnum {
  TEXTFIELD,
  DROPDOWN,
  RADIOBUTTON,
  CHECKBOX
}

export class SubExtendedData {
  public itemkey: number;
  public description: string;
  public dataSets: SubExtendedDataField[] = [];
  public inputType: InputTypeEnum;
}
