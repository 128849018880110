import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {LoginComponent} from '../../core/components/login/login.component';
import {ConfigService} from "../../core/services/config.service";
import {LoginService} from "../../core/components/login/login.service";
import {Globals} from "../../core/globals";
import {CssPropertiesEnum} from "../../core/models/css-properties.enum";
import {VHostService} from "../../core/services/v-host.service";
import {RedirectService} from "../../data/services/redirect.service";
import {Router} from "@angular/router";
import {LandingPageService} from "../../data/services/landing-page.service";
import {LandingPage} from "../../data/models/landing-page.model";
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  cards: { title: string, img: SafeUrl, description: string, route: string, isLogIn: boolean, extern: boolean }[] = [];

  @ViewChild('login') loginComponent: LoginComponent;
  showLogin: boolean = true;
  isAuthenticated: boolean = false;
  CssPropertiesEnum = CssPropertiesEnum;
  pages: LandingPage[];

  constructor(
    public translationService: TranslationService,
    public configService: ConfigService,
    public loginService: LoginService,
    public redirectService: RedirectService,
    public router: Router,
    public vHostService: VHostService,
    public landingPageService: LandingPageService) {
    this.loginService.getAuthData().subscribe((authData) => {
      this.isAuthenticated = !!authData;
      this.buildCards();
    });

    this.landingPageService.getLandingPages().subscribe(pages => {
      if(pages && pages.length > 0) {
        this.pages = pages;
        this.buildCards();
      }
    })
  }

  buildCards(): void {
    this.cards = [];
    this.pages?.forEach( page => {
      if (page.showOnLandingpage) {
        if ((page.showAfterLogin && this.isAuthenticated) || (page.showBeforeLogin && !this.isAuthenticated)) {
          this.cards.push({
            title: page.description,
            img: page.landingImageUrl,
            description: page.extendedDescription,
            route: page.link !== 'login' ? (page.isExtern ? page.externLink : page.link) : null,
            extern: page.isExtern,
            isLogIn: page.link === 'login'
          })
        }
      }
    });
  }

  ngOnInit(): void {
  }

  onClickEvent(): void {
    this.loginComponent.onClickEvent();
  }

  onImgError(event, imgPath): void {
    event.target.src = imgPath;
  }

  navigateToRoute(card) {
    if (card.extern) {
      this.redirectService.redirect(card.route, true);
    } else {
      this.router.navigate([card.route], {queryParamsHandling: "preserve"});
    }
  }
}
