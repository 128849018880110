import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {PaymentTransactionModel} from "../components/onlinepayment/paymentTransaction.model";

@Injectable({providedIn: 'root'})
export class PaymentProviderService {
  private paymentProviderResult = new BehaviorSubject<PaymentTransactionModel>(null);

  getPaymentProviderResultData(): Observable<PaymentTransactionModel> {
    return this.paymentProviderResult;
  }

  setNewTransaction(transaction: PaymentTransactionModel): void {
    this.paymentProviderResult.next(transaction);
  }
}
