import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExternalOffer} from '../../data/models/external-offer.model';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageService} from './image.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalOfferService {
  externalOffersForSlider: ExternalOffer[] = [];
  externalOffersForGroup: ExternalOffer[] = [];
  baseUrlSlider: string = '/webabo/externalOffer/slider';
  baseUrlOfferGroup: string = '/webabo/externalOffer/offerGroup/';

  constructor(private httpClient: HttpClient,
              private imageService: ImageService,
              private sanitizer: DomSanitizer) {
  }

  loadExternalOffersForSlider(): any {
    return this.httpClient.get<ExternalOffer[]>(this.baseUrlSlider);
  }

  loadExternalOffersForOfferGroup(offerGroupId: string): any {
    return this.httpClient.get<ExternalOffer[]>(this.baseUrlOfferGroup + offerGroupId);
  }

  getExternalOffersForSlider(): ExternalOffer[] {
    this.loadExternalOffersForSlider().subscribe(externalOffers => {
      if (externalOffers) {
        this.externalOffersForSlider = externalOffers;
        this.externalOffersForSlider.forEach(externalOffer => {
          this.imageService.getImageOrError(externalOffer.id).subscribe(image => {
            if (image && image.type && image.file) {
              externalOffer.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
              externalOffer.image = image;
              if (!externalOffer.imageUrl) {
                externalOffers.splice(externalOffers.indexOf(externalOffer));
              }
            }
          });
        });
      }
    });
    return this.externalOffersForSlider;
  }

  getExternalOffersForOfferGroup(offerGroupId: string): any {
    this.loadExternalOffersForOfferGroup(offerGroupId).subscribe(externalOffers => {
      if (externalOffers) {
        this.externalOffersForGroup = externalOffers;
        this.externalOffersForGroup.forEach(externalOffer => {
          this.imageService.getImageOrError(externalOffer.id).subscribe(image => {
            if (image && image.type && image.file) {
              externalOffer.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
              externalOffer.image = image;
            }
          });
        });
      }
    });
    return this.externalOffersForGroup;
  }
}
