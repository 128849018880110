export class OtlcData {
  userId?: number;

  static of(data: any): OtlcData {
    let otlcData = null;
    if (data) {
      otlcData = new OtlcData();
      otlcData.userId = data.userId ?? null;
    }

    return otlcData;
  }
}
