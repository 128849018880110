<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('contact_unauthorized_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('contact_unauthorized_description')">
  </div>
</div>
<div class="row">
  <div class="col-12">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div *ngIf="isLoading" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <app-client-search (validateButtonClick)="loadCustomerByClientNo($event)">
      <mat-error
        *ngIf="clientNoForm.get('clientNo').hasError('message')">{{clientNoForm.get('clientNo').getError('message')}}</mat-error>
    </app-client-search>
    <div class="mb-3" *ngIf="!clientFound && !isFirst"
         [innerHTML]="translationService.getTranslation('clientno_not_found')">
    </div>

    <div style="margin-left: -15px;margin-right: 15px;">
      <app-hup-subscription-address [showActivateCheckbox]="false"
                                    [controlGroup]="addressForm.get('contactAddress')"
                                    *ngIf="!clientFound">
      </app-hup-subscription-address>
    </div>
    <div *ngIf="!emailExists" style="margin-left: -15px;margin-right: 15px;">
      <div class="mb-3" *ngIf="clientFound" [innerHTML]="translationService.getTranslation('clientno_found_no_emailaddress_found')"></div>
      <div>
        <mat-form-field class="col-12">
          <mat-label>{{translationService.getTranslation('label_email')}}</mat-label>
          <input maxLength="50" matInput [(ngModel)]="emailAddress" #email>
          <mat-hint *ngIf="email === document.activeElement && email.value.length >=1 " align="end">{{email.value.length}} / {{email.maxLength}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="!isLoading">
      <app-contact [unauthorized]="true" [userBackendId]="customer?.userBackendId"
                   [addressForm]="addressForm.get('contactAddress')"
                   [emailAddress]="emailAddress"
                   (saved)="savedRequest($event)"></app-contact>
    </ng-container>
  </div>
</div>
