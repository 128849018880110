import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginBoxComponent} from './login-box/login-box.component';
import {LoginService} from './login.service';
import {TranslationService} from '../../services/translation.service';
import {Subscription} from 'rxjs';
import {AuthResponseData} from "./auth-response-data.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  authSub: Subscription;
  authData: AuthResponseData = null;

  constructor(// private activatedRoute: ActivatedRoute,
              // private http: HttpClient,
              // private renderer2: Renderer2,
              // @Inject(DOCUMENT) private document: Document,
              // @Inject(MAT_DIALOG_DATA) private data: {bonus: Bonus, showBonusPoints: boolean}
              private  dialog: MatDialog,
              private loginService: LoginService,
              public translationService: TranslationService,
              ) { }

  ngOnInit(): void {
    this.authSub = this.loginService.getAuthData().subscribe((authData) => {
      this.authData = authData;
      this.isAuthenticated = !!authData;
    });
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }

  onLogin(): void {
    const dialogRef = this.dialog.open(LoginBoxComponent, {
      // width: '250px',
      // data: {email: null, password: null}
      panelClass: 'SSOloginBox'
    });

    // dialogRef.afterClosed().subscribe(data => {
    //   // TODO: Feedback, dass der Login noch läuft - ggf. dialog noch nicht schließen?
    //   console.log('loginbox was closed');
    //   console.log(data);
    //   this.doLogin(data);
    // });
  }

  onLogout(): void {
    this.loginService.logout();
  }

  onClickEvent(): void {
    (this.isAuthenticated) ? this.onLogout() : this.onLogin();
  }

  refresh(): void {
    this.loginService.refresh().subscribe();
  }

}
