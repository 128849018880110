export class LogonStatusData {
  code?: string;

  static of(statusData: any): LogonStatusData {
    let logonStatus = null;
    if (statusData) {
      logonStatus = new LogonStatusData();
      logonStatus.code = statusData.code ?? null;
    }

    return logonStatus;
  }
}
