export class Person {
  constructor(
    public title: string,
    public salutation: string,
    public firstname: string,
    public lastname: string,
    public initials: string,
    public middleName: string,
    public salutationDescription?: string,
    public titleDescription?: string,
  ) {}
}


