import {Component, OnInit} from '@angular/core';
import {ComplaintService} from '../../../data/services/complaint.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../../core/services/translation.service';
import {ErrorService} from '../../../core/components/general-errors/error.service';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {ParentErrorStateMatcher} from '../../../core/parent-error.state-matcher';
import {ActivatedRoute} from '@angular/router';
import {HupSubscription} from '../../../data/models/subscription.model';
import {BaseDataService} from '../../../core/services/base-data.service';
import {BaseData} from '../../../data/models/base-data.model';
import {ConfigService} from '../../../core/services/config.service';
import {ConfigData} from '../../../data/models/config.model';
import {DatePipe} from "@angular/common";
import {Observable} from "rxjs";
import {StepperOrientation} from "@angular/cdk/stepper";
import {BreakpointObserver} from "@angular/cdk/layout";
import {map} from "rxjs/operators";
import {SnackbarService} from "../../../core/services/snackbar.service";
import {ComplaintCode} from "../../../data/models/complaint-code.model";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";

export interface ComplaintDay {
  name: string;
  completed: boolean;
  complaintDaysArray?: ComplaintDay[];
}

@Component({
  selector: 'app-complaint-edit',
  templateUrl: './complaint-edit.component.html',
  styleUrls: ['./complaint-edit.component.scss']
})
export class ComplaintEditComponent implements OnInit {

  complaintDays: ComplaintDay = {
    name: 'Alle reklamieren',
    completed: false,
    complaintDaysArray: []
  };

  sub: HupSubscription;
  isLoadingSub: boolean = false;
  isLoadingDueDates: boolean = false;
  complaintForm: FormGroup;
  complaintDueDatesForm: FormGroup;
  complaintCompensationForm: FormGroup;
  complaintMemoForm: FormGroup;
  compensationStep: boolean = false;
  isMobile: boolean = false;
  maxDate: Date = new Date();
  errorsArr: string[] = [];
  parentErrorStateMatcher = new ParentErrorStateMatcher();
  changeable: boolean = true;
  reasonGroupForPrint: ConfigData;
  reasonGroupForEpaper: ConfigData;
  reasons: ComplaintCode[];
  complaintActions = [];
  isUnauthorized: boolean = false;
  clientNo: string;
  allComplete: boolean = false;
  isSaving: boolean = false;
  errorMessage: string = '';
  public messageText: string = '';
  public messagesPresentText: string = '';
  public messagesPresent: boolean = false;
  showBackToOverviewButton: boolean;
  msgArr: string[] = [];

  stepperOrientation: Observable<StepperOrientation>;

  constructor(private complaintService: ComplaintService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private formBuilder: FormBuilder,
              private errorService: ErrorService,
              private route: ActivatedRoute,
              private baseDataService: BaseDataService,
              public translationService: TranslationService,
              private configService: ConfigService,
              private datePipe: DatePipe,
              private breakpointObserver: BreakpointObserver,
              private snackBar: SnackbarService,
              private navigationService: NavigationService,
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  updateAllComplete(): void {
    this.allComplete = this.complaintDays.complaintDaysArray != null && this.complaintDays.complaintDaysArray.every(t => t.completed);
    const dates = this.complaintDays.complaintDaysArray;
    // this.complaintDueDatesForm.get('dates').patchValue(dates);
  }

  someComplete(): boolean {
    if (this.complaintDays.complaintDaysArray == null) {
      return false;
    }
    const someDates = this.complaintDays.complaintDaysArray.filter(t => t.completed).length > 0 && !this.allComplete;
    // this.complaintDueDatesForm.get('dates').patchValue(someDates);
    return someDates;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.complaintDays.complaintDaysArray == null) {
      return;
    }
    this.complaintDays.complaintDaysArray.forEach(t => t.completed = completed);
    const dates: FormArray = this.complaintDueDatesFormDateArray;
    dates.controls.forEach(control => {
      control.patchValue(completed);
    });
  }

  ngOnInit(): void {
    this.complaintForm = this.formBuilder.group({
      subId: ['', Validators.required],
      reason: ['', Validators.required],
      reasonGroup: [''],
      validDate: this.formBuilder.group({
        validFrom: [this.maxDate, Validators.required],
        validUntil: ['']
      }),
    });

    this.complaintDueDatesForm = this.formBuilder.group({
      dates: new FormArray([])
    });

    this.complaintCompensationForm = this.formBuilder.group({
      compensation: ['', Validators.required]
    });

    this.complaintMemoForm = this.formBuilder.group({
      memo: [''],
    });

    this.reasonGroupForPrint = this.configService.getConfig('complaint.reasongroup.default');
    this.reasonGroupForEpaper = this.configService.getConfig('complaint.reasongroup.defaultOnline');

    this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
      if( config && config.value) {
        this.showBackToOverviewButton = config.value === '1' || config.value === 'true';
      }
    });

    this.setComplaintActions();

    this.route.paramMap.subscribe((params) => {
      const subWebId = params.get('subWebId');
      const subBackendId = params.get('subBackendId');
      this.clientNo = params.get('clientNo');
      this.isUnauthorized = params.get('unauthorized') === 'true';
      const isPrint = params.get('isPrint');
      this.changeable = params.get('changeable') === 'true';
      this.disableForms();
      if ((subWebId || subBackendId) && !this.isUnauthorized) {
        this.loadSubscription(subWebId, subBackendId);
      } else {
        this.sub = new HupSubscription(null, Number(this.clientNo), Number(subWebId), Number(subBackendId), false, null, null, null, null, null, null, null, null, null, null, false, null, null, null, null);
        this.complaintForm.get('subId').patchValue(subBackendId);
        const url = 'complaintgroupcodes/' + (isPrint && isPrint == 'true' ? this.reasonGroupForPrint.value : this.reasonGroupForEpaper.value) + '/complaintreasons';
        this.baseDataService.loadComplaintCodes(url).subscribe(baseData => {
          this.reasons = baseData;
          const reasonGroup = isPrint && isPrint == 'true' ? this.reasonGroupForPrint.value : this.reasonGroupForEpaper.value;
          this.complaintForm.get('reasonGroup').patchValue(reasonGroup);
        });
      }
      const complaintWebId = params.get('compWebId');
      const complaintBackendId = params.get('compBackendId');
      if (complaintWebId || complaintBackendId) {
        this.loadComplaint(complaintWebId, complaintBackendId);
      }
    });

    this.complaintCompensationForm.get('compensation').valueChanges.subscribe(value => {
      console.log(value);
    });

  }

  // @ts-ignore
  get complaintDueDatesFormDateArray() {
    return this.complaintDueDatesForm.controls.dates as FormArray;
  }

  addCheckBoxes() {
    this.complaintDueDatesFormDateArray.clear();
    this.complaintDays.complaintDaysArray.forEach((date) => this.complaintDueDatesFormDateArray.push(new FormControl(date.completed)))
  }

  loadSubscription(subWebId, subBackendId): void {
    this.hupSubscriptionsService.getSubscription(subWebId, subBackendId).subscribe(sub => {
      this.sub = sub;
      this.isLoadingSub = false;
      this.manageDelayMessages(sub);
      // set value in form?
      this.complaintForm.get('subId').patchValue(sub.backendId);
      const reasonGroup = sub.offer?.offerType?.ePaper ? this.reasonGroupForEpaper.value : this.reasonGroupForPrint.value
      this.complaintForm.get('reasonGroup').patchValue(reasonGroup);
      const url = 'complaintgroupcodes/' + reasonGroup + '/complaintreasons';
      this.baseDataService.loadComplaintCodes(url).subscribe(baseData => {
        this.reasons = baseData;
      });
    });
  }

  loadComplaint(complaintWebId, complaintBackendId): void {
    this.complaintService.getComplaintByString(complaintWebId, complaintBackendId).subscribe(comp => {
      const data = {
        ...JSON.parse(JSON.stringify(comp))
      };
      data.reason = data.type;
      data.reasonGroup = data.reasongroup;
      data.memo = data.memo.memoText;
      this.complaintForm.patchValue(data);
    });
  }

  disableForms(): void {
    if (!this.changeable) {
      this.complaintForm.disable();
      this.complaintCompensationForm.disable();
    }
  }

  setComplaintActions(): void {
    const actions = this.baseDataService.getBaseData('complaintactions');
    this.filterComplaintActions(actions);
  }

  private filterComplaintActions(actions: BaseData[]) {
    const actionsForWebConfig = this.configService.getConfig('complaint.webFlags');
    const actionsForWeb = actionsForWebConfig?.value.split(',');
    actions?.forEach(action => {
      if (actionsForWeb.find(key => key === action.key || key === action['code'])) {
        this.complaintActions.push(action);
      }
    });
  }

  getSelectedDates(): [] {
    const selectedDates = this.complaintDueDatesForm.value.dates
      .map((checked, i) => checked ? this.complaintDays?.complaintDaysArray[i]?.name : null)
      .filter(v => v !== null).sort((a, b) => {
        return a < b ? -1 : 1
      });
    return selectedDates;
  }

  changeComplaintDay(index: number) {
    if (this.complaintDays?.complaintDaysArray[index]) {
      this.complaintDays.complaintDaysArray[index].completed = !this.complaintDays?.complaintDaysArray[index]?.completed;
    }
  }

  onSend(): void {
    this.errorsArr = [];
    let checkedComplaintDays = this.countComplaintDays();
    if (this.complaintDays?.complaintDaysArray.length <= 0) {
      this.errorsArr.push(this.translationService.getTranslation('complaint_no_complaintDays'));
    } else if (this.getSelectedDates().length <= 0) {
      this.errorsArr.push(this.translationService.getTranslation('complaint_no_complaintDay_selected'));
    } else {
      this.isSaving = true;
      let sentComplaintDays = 0;
      this.complaintDays?.complaintDaysArray?.forEach(day => {
        if (day.completed) {
          const data = this.prepareFormForService();
          const dataToSave = {...data};
          dataToSave.validDate.validFrom = day.name;
          dataToSave.validDate.validUntil = day.name;

          if (this.isUnauthorized) {
            dataToSave.userBackendId = this.clientNo;
            this.complaintService.sendUnauthorizedComplaint(dataToSave).subscribe({
              next: res => {
                sentComplaintDays++;
                this.navigateToOverview(sentComplaintDays, checkedComplaintDays, '');
              },
              error: error => {
                sentComplaintDays++;
                let date : string = this.datePipe.transform(
                  dataToSave.validDate.validFrom,
                  this.translationService.getTranslation('dateFormat')
                );
                let msg : string = error.error.message
                  .substring(0, error.error.message.length - 2)
                  .replace('{"validDate":"', date + ': ');
                this.navigateToOverview(sentComplaintDays, checkedComplaintDays, msg);
              }
            });
          } else {
            this.complaintService.sendComplaint(dataToSave).subscribe({
              next: res => {
                sentComplaintDays++;
                this.navigateToOverview(sentComplaintDays, checkedComplaintDays, '');
              },
              error: error => {
                sentComplaintDays++;
                let date : string = this.datePipe.transform(
                  dataToSave.validDate.validFrom,
                  this.translationService.getTranslation('dateFormat')
                );
                let msg : string = error.error.message
                                    .substring(0, error.error.message.length - 2)
                                    .replace('{"validDate":"', date + ': ');
                this.navigateToOverview(sentComplaintDays, checkedComplaintDays, msg);
              }
            });
          }
        }
      });
    }
  }

  private countComplaintDays() {
    let checkedComplaintDays = 0;
    this.complaintDays?.complaintDaysArray.forEach(day => {
      if (day.completed) {
        checkedComplaintDays++;
      }
    });
    return checkedComplaintDays;
  }

  navigateToOverview(sentComplaintDays: number, checkedComplaintDays: number, msg : string): void {
    if(msg.length > 0) {
      this.msgArr.push(msg);
    }
    if (sentComplaintDays === checkedComplaintDays) {
      this.isSaving = false;
      this.complaintService.setErrorMessages(this.msgArr).subscribe(
        saved => {
          const openSnackBar = (navigated: boolean) => {
            if (navigated && this.msgArr.length < checkedComplaintDays) {
              this.snackBar.openSnackbar(this.translationService.getTranslation('saved'));
            }
          };
          if (!this.isUnauthorized){
            this.navigationService.navigateTo(NavigationCommand.COMPLAINT).then(openSnackBar);
          }else{
            this.navigationService.navigateTo(NavigationCommand.COMPLAINTWITHPUTLOGINAFTER).then(openSnackBar);
          }
        }
      );
    }
  }

  getComplaintCode(): ComplaintCode {
    return JSON.parse(JSON.stringify(this.complaintForm.value))?.reason;
  }

  prepareFormForService() {
    const data = {
      ...JSON.parse(JSON.stringify(this.complaintForm.value)),
      ...JSON.parse(JSON.stringify(this.complaintCompensationForm.value)),
      ...JSON.parse(JSON.stringify(this.complaintMemoForm.value)),
    };

    const memo = data.memo;
    data.memo = {};
    data.memo.memoText = memo;
    data.reason = data.reason.key;
    data.reasongroup = data.reasonGroup.value ?? data.reasonGroup;
    data.userBackendId = this.clientNo;
    if (data.compensation === '') {
      data.compensation = [];
    } else {
      const compensation = data.compensation;
      data.compensation = [];
      data.compensation.push({code: compensation.code, description: compensation.description});
    }

    return data;
  }

  loadDueDates(complaintCode: string): void {
    this.isLoadingDueDates = true;
    this.complaintDays.complaintDaysArray = [];
    this.complaintService.getDueDates(this.sub.backendId, complaintCode).subscribe(dueDates => {
      const onlyOne = dueDates && dueDates.length === 1;
      dueDates.sort((a, b) => {
        return new Date(b).getTime() - new Date(a).getTime()
      });

      dueDates?.forEach(dateString => {
        this.complaintDays.complaintDaysArray.push({name: dateString, completed: onlyOne});
      });

      if (onlyOne) {
        this.loadComplaintActionsByCodeAndDate(this.complaintForm.get('reason')?.value?.key);
      }
      this.addCheckBoxes();

      this.isLoadingDueDates = false;
    });
  }

  loadComplaintActionsByCodeAndDate(complaintCode: string): void {
    this.complaintActions = [];
    this.complaintDays.complaintDaysArray.forEach(day => {
      if (day.completed) {
        const date = new Date(day.name);
        if (date) {
          this.complaintService.findAllComplaintActionsForParams(this.sub.backendId, complaintCode, date.toISOString()).subscribe(actions => {
            this.filterComplaintActions(actions);
            if (this.complaintActions.length > 1) {
              this.compensationStep = true;
            } else {
              if (this.complaintActions.length == 1) {
                // den einen als aktiv markieren
                this.complaintCompensationForm.get('compensation').setValue(this.complaintActions[0]);
              }
              this.compensationStep = false;
            }
          });
        }
      }
    });
  }

  getPriceForComplaint() {
    /*
    let minDate =  new Date();
    let maxDate = new Date();
    let first = true;
    this.complaintDays?.complaintDays?.forEach(day => {

      if (day.completed) {
        const checkedDate = new Date(day.name);
        if( first) {
          minDate = checkedDate;
          maxDate = checkedDate;
          first = false;
        } else if (checkedDate.getDate() < minDate.getDate()) {
          minDate = checkedDate;
        } else if (checkedDate.getDate() > minDate.getDate()) {
          maxDate = checkedDate;
        }
      }
    });
    const data = this.prepareFormForService();
    data.validDate.validFrom = this.datePipe.transform(minDate, 'yyyy-MM-dd');
    data.validDate.validUntil = this.datePipe.transform(maxDate, 'yyyy-MM-dd');
    this.complaintService.getPriceForComplaint(data).subscribe(price => {
      console.log(price);
    });

     */
  }

  backToOverview(): void {
    this.navigationService.navigateTo(NavigationCommand.COMPLAINT, null, true);
  }

  compareBaseData(op1: BaseData, op2: any) {
    return op1.key === op2;
  }

  manageDelayMessages(sub: HupSubscription) {
    let delaysCount = 0;

    this.hupSubscriptionsService.getDelayMessages(sub.backendId + '').subscribe(messages => {
      if (messages != null && messages.length > 0) {
        delaysCount = messages.length;
      }

      if (delaysCount > 0) {
        this.messagesPresent = true;
        this.messagesPresentText = this.translationService.getTranslation('logistic_messages.present');
        this.messageText = this.datePipe.transform(messages[0].messageDate, 'dd.MM.yyyy')
          + ': ' + messages[0].classification.displayText + ". "
          + messages[0].messageText.extension + ' (' + this.translationService.getTranslation('logistic_messages.valid_until') +
          this.datePipe.transform(messages[0].delay.messageDelaysUntil, 'dd.MM.yyyy') + ')';
      }
    });
  }

// protected readonly print = print; todo: in der Fehlermeldung steht: print is not defined ?
  protected readonly document = document;
}
