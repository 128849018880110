import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-general-warning',
  templateUrl: './general-warning.component.html',
  styleUrls: ['./general-warning.component.css']
})
export class GeneralWarningComponent implements OnInit {
  @Input() warnings: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }
}
