import {Component, Input} from '@angular/core';
import {ExternalOffer} from '../../../data/models/external-offer.model';
import {TranslationService} from '../../../core/services/translation.service';
import {NavigationService} from '../../../data/services/navigation.service';

@Component({
  selector: 'app-external-offer-card',
  templateUrl: './external-offer-card.component.html',
  styleUrls: ['./external-offer-card.component.css']
})
export class ExternalOfferCardComponent {
  @Input() externalOffer: ExternalOffer;
  @Input() showOnOfferPage: boolean = true;

  constructor(public navigationService: NavigationService,
              public translationService: TranslationService) {
  }
}
