import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bankAccount'
})
export class BankAccountPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Ziffern außer den letzten 4 durch 'X' ersetzen
    return value.slice(0, -4).replace(/\d/g, 'X') + value.slice(-4);
  }
}
