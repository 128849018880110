import {ValidDate} from './valid-date.model';
import {Payment} from './payment.model';
import {AddressListItem} from './address-list.model';
import {SubChange} from './sub-change.model';
import {Offer} from "./offer.model";
import {MessageSearchResult} from "./message-search-result";

export class HupSubscription {
  // @ts-ignore
  constructor(
    public subId: number,
    public userBackendId: number,
    public webId: number,
    public backendId: number,
    public readOnly: boolean,
    public state: number,
    public variantCode: string,
    public offerId: number,
    public offer: Offer,
    public validDate: ValidDate,
    public commitmentDate: Date,
    public paymentList: Payment[],
    public deliveryAddressList: AddressListItem[],
    public billingAddressList: AddressListItem[],
    public subChanges?: SubChange[],
    public isLoadingSubChange?: boolean,
    public productCode?: string,
    public productDescription?: string,
    public variantDescription?: string,
    public curDelivery?: AddressListItem,
    public curBilling?: AddressListItem,
    public curPayment?: Payment,
    public delayMessages: MessageSearchResult[] = [],
  ) {
  }
}
