<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('subscription_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('subscription_description')">
  </div>

  <!--  <div class="col-12 col-md-9">-->
  <!--    <h5 [innerHTML]="translationService.getTranslation('text_payment')"></h5>-->
  <!--  </div>-->
  <!--  <div class="col-12 col-md-3">-->
  <!--    <a routerLink="/payment"><button mat-raised-button>{{translationService.getTranslation('text_payment')}}</button></a>-->
  <!--  </div>-->
</div>
<div class="row">
  <div class="col-12">
    <div *ngIf="isLoading" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="!isLoading && subs.length == 0" [innerHTML]="translationService.getTranslation('intro_noSubs')">
    </div>
    <ng-container *ngIf="!isLoading && subs.length > 0">
      <mat-card appearance="outlined" *ngFor="let sub of subs; let i = index" class="mt-3">
        <div class="row m-3">
          <div class="col-12 col-md-6">
            <mat-card-title>{{sub.productDescription}}</mat-card-title>
            <mat-card-subtitle *ngIf="sub.subId" [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.subId">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.userBackendId" [innerHTML]="translationService.getTranslation('label_backendid') + ': ' + sub.userBackendId">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.variantDescription" [innerHTML]="translationService.getTranslation('order_edition') + ': ' + sub.variantDescription">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.validDate" [innerHTML]="translationService.getTranslation('period') + ': ' +
                               (sub.validDate.validFrom | date: translationService.getTranslation('dateFormat')) + ' - ' +
                               ((sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless'))">
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="sub.curDelivery">
              {{sub.curDelivery.person.salutationDescription}}
              {{sub.curDelivery.person.firstname}}
              {{sub.curDelivery.person.lastname}},
              {{sub.curDelivery.address.city}},
              {{sub.curDelivery.address.street}} {{sub.curDelivery.address.houseno | houseNoPipe}}
            </mat-card-subtitle>
          </div>
          <div class="col-12 col-md-6">
            <mat-card-actions class="card-actions">
              <!--
              <button mat-raised-button color="primary" *ngIf="sub.state === -1"
                      (click)="cancelOrder(sub)">{{translationService.getTranslation('button_storno')}}</button>
                      -->
              <button mat-raised-button color="primary" [innerHTML]="translationService.getTranslation('button_details')"
                      (click)="aboDetails(sub)"></button>
            </mat-card-actions>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
