import {Address} from './address.model';
import {Person} from './person.model';

export class AddressListItem {
  constructor(
    public userBackendId: number,
    public address: Address,
    public person: Person,
    public emailList: [],
  ) {
  }
}

