import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'houseNoPipe'})
export class HouseNoPipe implements PipeTransform {
  transform(houseno: string): string {
    if (houseno && houseno.startsWith('01')) {
      return houseno.replace('01#', '').split('#').join('/');
    } else if (houseno && houseno.startsWith('00')) {
      return houseno.replace('00#', '').split('#').join( '');
    } else {
      return houseno;
    }
  }
}
