export class Address {
  constructor(
      public street: string,
      public houseno: string,
      public zipcode: string,
      public city: string,
      public country: string,
      public webId: number,
      public backendId: number,
      public userBackendId: number,
      public userSSOId: number,
      public district: string,
      public longitude: number,
      public latitude: number,
      public countryDescription?: string,
  ) {}
}
