export class AuthResponseData {
  token: string;
  code: string;
  timeToLive: number;
  refreshToken: string;
  expiration_date?: Date;

  static of(authData: any): AuthResponseData {
    if( authData) {
      const result = new AuthResponseData();
      result.token = authData.token ?? '';
      result.timeToLive = authData.timeToLive ?? 0;
      result.refreshToken = authData.refreshToken ?? 0;
      result.expiration_date = authData.expiration_date ?? new Date();
      result.code = authData.code ?? '';
      return result;
    } else {
      return null;
    }
  }
}
