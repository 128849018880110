import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) {
  }

  openSnackbar(message: string): void {
    this._snackBar.open(message, null, {duration: 10000, horizontalPosition: "center", verticalPosition: "bottom"});
  }

  closeSnackbar(): void {
    this._snackBar.dismiss();
  }
}
