import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HTTP_INTERCEPTORS} from '@angular/common/http';
import {take, exhaustMap} from 'rxjs/operators';
import {LoginService} from './login.service';
import {Observable} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class LoginInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    @Inject(PLATFORM_ID) private platformId: Object) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.loginService.getAuthData().pipe(
      take(1),
      exhaustMap(user => {
        let modifiedReq = req.clone();
        if (isPlatformBrowser(this.platformId)) {
          modifiedReq = req.clone({
            headers: req.headers.set('X-WEBABO-PROFILEKEY', vHost.id),
          });
        }
        if ( user?.token) {
          const tokenReq = modifiedReq.clone({
            headers: modifiedReq.headers.append('X-WEBABO-SSOTOKEN', user.token)
              .append('Cache-Control', 'no-cache, no-store, must-revalidate')
              .append('Pragma', 'no-cache')
              .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
              .append('If-Modified-Since', '0'),
          });
          return next.handle(tokenReq);
        } else {
          return next.handle(modifiedReq);
        }
      })
    );
  }
}
