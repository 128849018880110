import {OffcanvasDismissReasons} from "@ng-bootstrap/ng-bootstrap";

export class OfferPrice {
  constructor(
    public amount: number,
    public calculationPeriod: {
      validFrom: Date,
      validUntil: Date
    },
    public currency: string,
    public price: number,
    public priceText: string,
    public trial: boolean
  ) {
  }
}
