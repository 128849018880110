import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {BaseDataService} from './base-data.service';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class BaseDataResolverService implements Resolve<any> {
  constructor(private baseDataService: BaseDataService, private http: HttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const baseDataTypes: [] = route.data.baseData;
    const observableBatch = [];
    let observe = false;

    baseDataTypes.forEach((type: string) => {
      const baseData = this.baseDataService.getBaseData(type);
      if (!baseData) {
        observe = true;
        observableBatch.push(this.baseDataService.loadBaseData(type));
      }
    });

    if (observe) {
      return forkJoin(observableBatch);
    }
    else { // Ein leeres forkJoin feuert success() nie -> Pfad wäre dann tot
      return;
    }
  }
}
